<template>
    <b-col class="mb-5 h-100 col-lg-4 col-sm-12 col-md-4">
      <div class="Mask">
        <div class="w-100 d-flex" style="justify-content: space-between;">
        <div class="mask-title"></div>
        <img class="mask-image" />
        </div>
        <div class="mask-list">
          <ul class="p-0">
            <li
              v-for="(stats, index) in 6"
              :key="index"
              class="d-flex justify-content-between mb-5"
            >
              <div class="mask-list-item">
                <i class=" small mr-3"></i>
              </div>
              <div class="mask-list-score"></div>
            </li>
          </ul>
        </div>
        <div class="mask-cta text-right">
          <button
            color="primary isi"
            class="btn-effect inActive-btn-effect"
            size="button--size-m"
          ></button>
        </div>
      </div>
    </b-col>
</template>

<script>
export default {};
</script>

<style scoped>
.Mask {
  max-width: 456px;
  width: 100%;
  height: 100%;
  padding: 32px;
  object-fit: contain;
  box-shadow: 0 0 8px 0 #c9ced5;
  background-color: #fff;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.mask-title {
  font-family: ProximaNovaBold;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #222a37;
  animation: pulse-bg 1.5s infinite;
  width: 44%;
  height: 28px;
}
.mask-subtitle {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #9aafd4;
  animation: pulse-bg 1.5s infinite;
}
.mask-image {
  width: 25%;
  padding-top: 70px;
  animation: pulse-bg 1.5s infinite;
  padding-left: 20px;
}
.mask-list {
  margin-top: 76px;
}
.mask-list .mask-list-item {
  font-family: ProximaNovaRegular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: #222a37;
  animation: pulse-bg 1.5s infinite;
}
.mask-list .mask-list-score {
  font-family: ProximaNovaBold;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: #222a37;
  animation: pulse-bg 1.5s infinite;
  width: 100%;
}
.btn-effect {
  /* margin: 70px 0px 20px 65px; */
  width: 190px;
  height: 60px;
  font-family: ProximaNovaRegular;
  border: 0px solid black;
  text-align: left;
  font-size: 16px;
  padding-left: 20px;
  border-radius: 5px;
  animation: pulse-bg 1.5s infinite;
}
</style>
